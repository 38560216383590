import React, { Component } from 'react';
import oldGlobalStyles from '../../styles/global.module.css';
import newGlobalStyles from '../../styles/global-rebrand.module.css';
import legacyStyles from './thankyou.module.css';
import rebrandedStyles from './thankyou-rebrand.module.css';
import { isRebranded } from '../../utils/featureFlagHelpers';

class Thankyou extends Component {

  render() {
    const styles = isRebranded() ? rebrandedStyles : legacyStyles;
    const global = isRebranded() ? newGlobalStyles : oldGlobalStyles;
    const buyer = this.props.buyer.permalink;
    const content = this.props.form;
    const additionalContent = this.props.thanksContent;
    let footerBottom;
    if (this.props.referralContent) {
      footerBottom = this.props.referralContent.footerbottom;
    }
    return (

      <div className={global.c2foContainer__inner}>
        <div className={`${global.c2foApp} ${styles.thankyou__paddingTop}`}>
          <div className={styles.thankyou__BuyerContainer}>
            <div className={`${styles.thankyou__Buyercontent} ${footerBottom ? styles.footer_bottom : ''}`}>

              {this.props.referralContent ?
              <>
                <h3 className={`${global.c2foText__navy} ${styles.header__spacing}`}>
                  {content.referralThankYouHeadline} {this.props.referralContent.referralFirstName} {this.props.referralContent.referralLastName}
                  </h3>
                  <p>
                  {this.props.referralContent.referralFirstName} {content.referralThankYouBodyCopy}
                  </p>
                </>
                :
                <>
                {
                  (isRebranded() ? 
                  <h1 className={`${global.c2foText__navy} ${styles.header__spacing}`}>
                    {content.thankYouEmailHeadline}
                  </h1>
                  :
                  <h3 className={`${global.c2foText__navy} ${styles.header__spacing}`}>
                    {content.thankYouEmailHeadline}
                  </h3>)
                }
                 <p>
                 {content.thankYouEmailSubheadline}
                 </p>
                 </>
              }
              
                <p>{additionalContent.needHelp} <a href={`mailto:${buyer}@c2fo.com`}>{additionalContent.contactSupport}</a> </p>
            </div>
            <div className={styles.thankyou__icon_container}>
              { isRebranded() ? 
              <img src="/images/email_icon.png" alt="open-email" />
              :
              <img src="/images/c2fo_email.svg" alt="open-email" />
              }
            </div>
          </div>

        </div>
      </div>

    );
  }
}

export default Thankyou;

